<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="24">
          <h2>Категори</h2>
        </el-col>
      </el-row>
    </header>
    <el-row :gutter="20">
      <el-col :span="10">
        <div class="panel">
          <div class="panel-item">
            <el-input
              v-model="filterText"
              placeholder="Категори хайх"
              class="mb20">
            </el-input>
          </div>
          <div class="panel-item">
            <div class="text-right">
              <el-button size="small" type="text" @click="onPressNewCategory(0, 'newCategory')">Шинэ</el-button>
            </div>
            <el-tree
              :data="categoryData"
              :filter-node-method="filterNode"
              ref="tree"
              :props="defaultProps"
              @node-click="handleNodeClick"
              draggable
              @node-drag-end="handleDragEnd"
              v-loading="isLoading">
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <div class="image-container" v-if="Array.isArray(data.image) && data.image.length > 0">
                  <el-image
                    class="image"
                    :src="data.image && data.image[0] ? data.image[0] : ''"
                    fit="contain">
                  </el-image>
                </div>
                <div class="text">{{ node.label }}</div>
                <span class="action">
                  <el-button
                    type="text"
                    size="mini"
                    @click="() => onPressNewCategory(data.uniq_id, 'subCategory')">
                    Хавсаргах
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="() => onPressRemoveCategory(node, data)">
                    Устгах
                  </el-button>
                </span>
              </div>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="14">
        <el-form>
          <div class="panel">
            <div class="panel-item">
              <header>Категори засах</header>
              <el-row :gutter="20">
                <el-col :span="20">
                  <el-form-item label="Монгол">
                    <el-input v-model="selectedNode.name_mon" placeholder="Эрэгтэй, эмэгтэй, цахилгаан бараа"></el-input>
                  </el-form-item>
                  <el-form-item label="Англи">
                    <el-input v-model="selectedNode.name_eng" placeholder="Man, woman, electric"></el-input>
                  </el-form-item>
                  <el-checkbox class="mb10" v-model="selectedNode.is_active">Идэвхитэй категори</el-checkbox>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Google Category">
                        <tree-selector v-model="selectedNode.google_category_id" :treeData="googleCategoriesList" multiple width="100%" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="selectedNode.parent === 0">
                      <el-form-item label="Апп категори (tag)">
                        <el-select v-model="selectedNode.tag" placeholder="Tag сонгох" clearable class="block">
                          <el-option
                            v-for="(tag, indexTag) in tagData"
                            :key="indexTag"
                            :label="tag.name_mon"
                            :value="tag.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item v-if="Array.isArray(selectedNode.image) && selectedNode.childrens[0] || Array.isArray(selectedNode.image) && selectedNode.image.length > 0">
                    <el-upload
                      list-type="picture-card"
                      class="mt5"
                      accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                      action=""
                      :file-list="selectedNode.image"
                      :auto-upload="false"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="(file, fileList) => handleRemove(file, 'exist', fileList)"
                      :on-change="(file, fileList) => getFile(file, 'exist', fileList)">
                      <span>150x150</span>
                    </el-upload>
                  </el-form-item>
                  <loader :active="isUploading"/>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="text-right border-top pt20">
            <el-button :type="selectedNode.uniq_id && selectedNode.name_mon !== '' && !isUploading && selectedNode.name_eng !== '' ? 'success' : 'disabled'" :disabled="selectedNode.uniq_id && selectedNode.name_mon !== '' && !isUploading && selectedNode.name_eng !== '' ? false : true" @click="updateCategory">Хадгалах</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- New Category -->
    <el-dialog
      title="Шинэ категори үүсгэх"
      :visible.sync="visibleCategoryDialog"
      width="500">
      <el-form label-position="top" :model="newCategoryForm" @submit.native.prevent="onPressSaveCategory">
        <el-row :gutter="20" v-loading="isLoading">
          <el-col :span="12">
            <el-form-item label="Монгол нэр">
              <el-input v-model="newCategoryForm.name_mon" placeholder="Цахилгаан бараа ..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Англи нэр">
              <el-input v-model="newCategoryForm.name_eng" placeholder="Electric ..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Зураг">
              <el-upload
                list-type="picture-card"
                class="mt10"
                accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                action=""
                :file-list="newCategoryForm.image"
                :auto-upload="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="(file, fileList) => handleRemove(file, 'new', fileList)"
                :on-change="(file, fileList) => getFile(file, 'new', fileList)">
                <span>150x150</span>
              </el-upload>
              <loader :active="isUploading"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Апп категори (tag)">
              <el-select v-model="newCategoryForm.tag" placeholder="Tag сонгох" clearable class="block">
                <el-option
                  v-for="(tag, indexTag1) in tagData"
                  :key="indexTag1"
                  :label="tag.name_mon"
                  :value="tag.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onPressCancelNewCategory">Болих</el-button>
        <el-button :disabled="isUploading" :type=" isUploading ? 'disabled' : 'success'" @click="onPressSaveCategory">Үүсгэх</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Дэд категори үүсгэх"
      :visible.sync="visibleSubCategoryDialog"
      width="500">
      <el-form label-position="top" :model="newCategoryForm" @submit.native.prevent="onPressSaveCategory">
        <el-row :gutter="20" v-loading="isLoading">
          <el-col :span="12">
            <el-form-item label="Монгол нэр">
              <el-input v-model="newCategoryForm.name_mon" placeholder="Цахилгаан бараа ..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Англи нэр">
              <el-input v-model="newCategoryForm.name_eng" placeholder="Electric ..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleSubCategoryDialog = false">Болих</el-button>
        <el-button :disabled="isUploading" :type=" isUploading ? 'disabled' : 'success'" @click="onPressSaveCategory">Үүсгэх</el-button>
      </span>
    </el-dialog>
    <!-- Image -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import * as googleCategories from '../../../assets/googleCategory.json'
import services from '../../../helpers/services'
import TreeSelector from '../../../components/common/treeSelector'
import { handlers } from '../../../helpers/custom'
import Loader from '../../../components/common/loader'
const newChild = {
  name_mon: '',
  name_eng: '',
  childrens: [],
  image: [],
  parent: 0,
  tag: ''
}
const newSelectedChild = {
  name_mon: '',
  name_eng: '',
  childrens: [],
  is_active: false,
  image: [],
  parent: 0,
  tag: ''
}
let count = 0
let sortedArray = []
export default {
  name: 'category',
  components: {
    TreeSelector,
    Loader
  },
  data () {
    return {
      tagData: [],
      googleCategoriesList: [],
      sort: '',
      filterText: '',
      confirmLeaveForm: false,
      visibleCategoryDialog: false,
      visibleSubCategoryDialog: false,
      newCategoryForm: newChild,
      selectedNode: newSelectedChild,
      isLoading: false,
      categoryData: [],
      defaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      isUploading: false
    }
  },
  mounted () {
    this.googleCategoriesList = googleCategories.default
    this.getCategories(true)
    this.getTag()
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    getTag () {
      const query = '?search_text=' + '' + '&from=' + 0 + '&size=' + 100 + '&field=' + '' + '&sort=' + '' + '&type=category'
      services.getTag(query).then((response) => {
        this.tagData = response.datas
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name_mon.indexOf(value) !== -1
    },

    getCategories (isLoad) {
      if (isLoad) {
        this.isLoading = true
      }
      services.getCategoriesAsTree().then(response => {
        this.categoryData = response
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },

    onPressCancelNewCategory () {
      this.visibleCategoryDialog = false
    },

    onPressSaveCategory () {
      if (this.newCategoryForm.name_mon === '' || this.newCategoryForm.name_eng === '') {
        this.notify('Алдаа', 'Та категорын мэдээллээ шалгана уу', 'error')
      } else {
        this.isLoading = true
        this.visibleCategoryDialog = false
        var tempCategory = JSON.parse(JSON.stringify(this.newCategoryForm))
        var images = []
        tempCategory.image.forEach((element) => {
          images.push(element.url)
        })
        tempCategory.image = images
        services.createCategory(tempCategory).then(response => {
          if (response.status === 'success') {
            this.notify('Амжилттай', response.message, 'success')
            this.getCategories(false)
            this.visibleSubCategoryDialog = false
          } else {
            this.isLoading = true
            this.notify('Алдаа', response.message, 'error')
          }
        }).catch(() => {
          this.isLoading = false
        })
      }
      this.newCategoryForm.name_mon = ''
      this.newCategoryForm.name_eng = ''
      this.newCategoryForm.image = []
    },

    onPressNewCategory (id, type) {
      this.newCategoryForm.parent = id
      if (type === 'newCategory') {
        this.visibleCategoryDialog = true
      }
      if (type === 'subCategory') {
        this.visibleSubCategoryDialog = true
      }
    },

    onPressRemoveCategory (node, data) {
      services.deleteCategory(data.uniq_id).then(response => {
        if (response.status === 'success') {
          this.notify('Амжилттай', response.message, 'success')
          this.getCategories(true)
        } else {
          this.notify('Алдаа', response.message, 'error')
        }
      })
    },

    updateCategory () {
      if (this.selectedNode && this.selectedNode.uniq_id) {
        this.isLoading = true
        var images = []
        this.selectedNode.image.forEach((element) => {
          images.push(element.url)
        })
        const body = {
          category_id: this.selectedNode.uniq_id,
          category: {
            name_mon: this.selectedNode.name_mon,
            name_eng: this.selectedNode.name_eng,
            google_category_id: this.selectedNode.google_category_id,
            image: images,
            parent: this.selectedNode.parent,
            is_active: this.selectedNode.is_active,
            tag: this.selectedNode.tag
          }
        }
        services.updateCategory(this.selectedNode.uniq_id, body).then(response => {
          this.notify('Амжилттай', response, 'success')
          this.selectedNode = newSelectedChild
          this.getCategories(false)
        }).catch(() => {
          this.isLoading = false
        })
      } else {
        this.notify('Алдаа', 'Уучлаарай категорио сонгоно уу', 'error')
      }
    },

    notify (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    handleNodeClick (data) {
      var tempData = JSON.parse(JSON.stringify(data))
      var images = []
      if (tempData.image && Array.isArray(tempData.image) && tempData.image.length !== 0) {
        tempData.image.forEach((element) => {
          images.push({ url: element })
        })
      }
      tempData.image = images
      this.selectedNode = tempData
    },

    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      if (dropType === 'inner' && dropNode) {
        const body = {
          category_id: draggingNode.data.uniq_id,
          category: {
            name_mon: draggingNode.data.name_mon,
            name_eng: draggingNode.data.name_eng,
            image: draggingNode.data.image,
            parent: dropNode && dropNode.data.uniq_id ? dropNode.data.uniq_id : 0,
            is_active: draggingNode.data.is_active
          }
        }
        this.dragUpdateCategory(body)
      } else if (dropType === 'before' && dropNode) {
        const body = {
          category_id: draggingNode.data.uniq_id,
          category: {
            name_mon: draggingNode.data.name_mon,
            name_eng: draggingNode.data.name_eng,
            image: draggingNode.data.image,
            parent: dropNode && dropNode.data.parent ? dropNode.data.parent : 0,
            is_active: draggingNode.data.is_active
          }
        }
        this.dragUpdateCategory(body)
      }
      this.generateSort(dropType)
    },

    updateAllCategorySort (categoryData) {
      this.isLoading = true
      const body = {
        categories: categoryData
      }
      services.updateAllCategorySort(body).then(response => {
        this.notify('Амжилттай', response, 'success')
        this.getCategories(false)
      }).catch(() => {
        this.isLoading = false
      })
    },

    dragUpdateCategory (body) {
      this.isLoading = true
      services.updateCategory(body.category_id, body).then(response => {
        this.notify('Амжилттай', response, 'success')
        this.selectedNode = newSelectedChild
        this.getCategories(false)
      }).catch(() => {
        this.isLoading = false
      })
    },

    generateSort () {
      const tempCategoryData = JSON.parse(JSON.stringify(this.categoryData))
      const tempData = this.parseObjectProperties(tempCategoryData)
      sortedArray = []
      count = 0
      this.updateAllCategorySort(tempData)
    },

    parseObjectProperties (array) {
      array.forEach(element => {
        count++
        element.sort = count
        sortedArray.push(element)
        if (element.childrens && Array.isArray(element.childrens) && element.childrens.length > 0) {
          this.parseObjectProperties(element.childrens)
        }
      })
      return sortedArray
    },

    handleRemove (file, type, fileList) {
      var tempId = 0
      if (type === 'exist') {
        this.selectedNode.image.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.selectedNode.image.splice(tempId, 1)
      } else if (type === 'new') {
        this.newCategoryForm.image.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.newCategoryForm.image.splice(tempId, 1)
      }
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file, type, fileList) {
      handlers.checkImageSize(file, true, 150, 150).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              if (type === 'exist') {
                this.selectedNode.image.push({
                  url: response.url
                })
              } else if (type === 'new') {
                this.newCategoryForm.image.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
